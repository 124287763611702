import CopyBtn from "./CopyBtn"

const RawInfo = ({title,data}) => {
  return (
    <div>
        <h2 className='text-xl'>
            {title}</h2>
        {
            data.map((item)=>{
                return <RawInfoItem key={item.keyword} {...item } />
            })
        }
    </div>
  )
}

export default RawInfo


const RawInfoItem = ({keyword,text})=>{
    return (
        <div className='my-2'>
            <h3 className='my-2 flex items-center text-sm'>{keyword} 
            <CopyBtn text={text} />
            </h3>
            <p className='text-xs p-1 px-2 rounded-md bg-white'>
                {text}
            </p>
        </div>
    )
}