import { useContext } from 'react'
import InfoContext from './InfoContext'
import ExifInfo from './ExifInfo'
import RawInfo from './RawInfo'
import VideoIcon from './assets/video.svg'
import DocIcon from './assets/doc.svg'
import Alert from './Alert'

const Info = () => {
    const { imageExif, imageFormatTags, imageRawTags, imageSelected } = useContext(InfoContext)

    return (
        <div>
            <h1 className='font-bold text-3xl'>
                Stable Diffusion TAGs 解析
            </h1>
            <p className='text-gray-500 my-2 text-sm'>
                从 Stable Diffusion 生成的图片读取 prompt / Stable Diffusion 模型解析
            </p>
            <p className='text-gray-500 my-2 text-sm flex items-center'>
                <img src={VideoIcon} alt="" className='w-4 h-4 mr-1' /> 视频演示、<img src={DocIcon} alt="" className='w-4 h-4 mr-1' /> 原理解析
            </p>
            {
                (imageSelected && !imageRawTags) && <Alert />
            }
            {
                imageRawTags && <RawInfo title={"完整TAG"} data={imageRawTags} />
            }

            {
                imageFormatTags && <RawInfo title={"格式化TAG"} data={imageFormatTags} />
            }

            {
                imageExif && <ExifInfo title={"EXIF"} data={imageExif} />
            }


        </div>
    )
}

export default Info