import { useState,useContext } from 'react'
import AddIcon from './assets/add-image.svg'
import LoadingIcon from './assets/loading.svg'
import ResetIcon from './assets/reset.svg'
import { readExif,readRawAITags,formatAITags } from './util'
import InfoContext from './InfoContext'

const Image = () => {
    const [imgSrc, setImgSrc] = useState(null)
    const {setImageExif,setImageRawTags,setImageFormatTags,setImageSelected} = useContext(InfoContext);
    const [loading, setLoading] = useState(false)

    const resetImage = () => {
        setImgSrc(null)
        setImageExif(null);
        setImageRawTags(null);
        setImageFormatTags(null)
        setImageSelected(false)
    }
    const triggerFileInput = () => {
        document.getElementById('select-image').click()
    }

    const handleFileSelected = (e) => {
        const file = e.target.files[0]
        const reader = new FileReader()
        setLoading(true)
        reader.onload = async(e) => {
            setImgSrc(e.target.result)
            setImageSelected(true)
            setLoading(false)
            const exifInfo = await readExif(file);
            setImageExif(exifInfo)
            const rawTags = await readRawAITags(file);
            setImageRawTags(rawTags);
            let formatedTags = null;
            if (rawTags && rawTags.length === 1) {
                formatedTags = formatAITags(rawTags[0])
            }
            setImageFormatTags(formatedTags);
        }
        reader.readAsDataURL(file)
    }

    return (
        <div className='w-full bg-white rounded-lg group'>
            {!imgSrc &&
                <div className="w-full h-60 md:h-80 cursor-pointer relative">
                    <div className="absolute left-4 top-4 right-4 bottom-4 md:left-8 md:top-8 md:right-8 md:bottom-8 border-2 border-dashed border-gray-200 hover:border-gray-400 transition-all rounded-lg text-gray-400 text-xl items-center flex flex-col justify-center" onClick={triggerFileInput}>
                        {
                            loading ? <span className='flex justify-items-center h-6'>
                            <img src={LoadingIcon} alt="" className='w-6 h-6 mr-2 animate-spin' /> 图片加载</span> : <span className='flex justify-items-center h-6'>
                            <img src={AddIcon} alt="" className='w-6 h-6 mr-2' /> 选取图片</span>
                        }
                        <p className='text-xs text-gray-200 mt-2'>
                            * 图片由本地浏览器解析 不会上传到服务器
                        </p>
                    </div>
                </div>
            }

            {imgSrc &&
                <div className='relative bg-white rounded-lg p-3'>
                    <button onClick={resetImage} className="flex absolute top-6 right-5 bg-white py-1 px-3 rounded-full transition-all items-center opacity-0 group-hover:opacity-100">
                        <img src={ResetIcon} alt="" className="w-4 h-4 mr-2" />重新上传</button>
                    <img id='selected-image' src={imgSrc} className="block align-top w-full rounded-md" alt='' />
                </div>
            }

            <input type="file" accept="image/*" className="hidden" id='select-image' onChange={handleFileSelected}></input>
        </div>
    )
}

export default Image