import ToolIcon from './assets/tool.svg'
import IslandIcon from './assets/island.svg'
function Head() {
    return (
        <>
            <header>
                <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold leading-tight text-gray-900 text-center">
                        <img alt="logo" src="https://moligirls.com/wp-content/themes/moli/assets/logo.png" width="240" height="40" className="mx-auto" loading="lazy" />
                    </h1>
                    <p><img alt="subtitle-logo" src="https://moligirls.com/wp-content/themes/moli/assets/logo2.png" width="240" height="40" className="mx-auto" loading="lazy" /></p>
                    <div className="flex flex-wrap justify-between md:w-[768px] mx-auto my-2 md:text-2xl">
                        <a href="https://moligirls.com"><img className="emoji" alt="🏠" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f3e0.svg" /> 首页</a>
                        <a href="https://moligirls.com/category/girl/"><img className="emoji" alt="💞" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f49e.svg" /> 妹子图</a>
                        <a href="https://moligirls.com/category/things/"><img className="emoji" alt="🚀" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f680.svg" /> 好物</a>
                        <a href="https://moligirls.com/category/landscape/" className="flex"><img alt="landscape-icon" src={IslandIcon} className="mx-auto w-4 md:w-7 mr-1" loading="lazy" /> 风景</a>
                        <a href="https://moligirls.com/member" className='hidden md:inline'><img className="emoji" alt="💝" src="https://s.w.org/images/core/emoji/14.0.0/svg/1f49d.svg" /> 会员</a>
                        <a href="https://aitags.moligirls.com"><img className="emoji" alt="📚" src={ToolIcon} /> AI TAGs 解析</a>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Head