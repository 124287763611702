import AlertIcon from './assets/alert.svg'

const Alert = () => {
  return (
    <div className='my-2 bg-yellow-200 p-2 rounded-md'>
     <p className='text-gray-500 my-2 text-sm flex items-center'>
        <img src={AlertIcon} alt="" className='w-4 h-4 mr-1' />
        该图无Prompt信息:可能不是原图, 或经编辑压缩丢失Prompt信息。
                </p>
    </div>
  )
}

export default Alert