import ExifReader from 'exifreader';
import extractChunks from "png-chunks-extract";
import text from "png-chunk-text";

export async function readExif(file) {
    const data = await ExifReader.load(file);
    const entries = Object.entries(data);
    return entries.map(([key, value]) => ({ 
        "keyword":key, 
        value 
    }));
}

export const readRawAITags = async (file) => {
    const buf = await file.arrayBuffer();
    let chunks = [];
    try {
        chunks = extractChunks(new Uint8Array(buf));
    } catch (err) {
        return null;
    }
    const textChunks = chunks
        .filter(function (chunk) {
            return chunk.name === "tEXt" || chunk.name === "iTXt";
        })
        .map(function (chunk) {
            if (chunk.name === "iTXt") {
                let data = chunk.data.filter((x) => x !== 0x0);
                let txt = new TextDecoder().decode(data);
                return {
                    keyword: "信息",
                    text: txt.slice(10),
                };
            }
            return text.decode(chunk.data);
        });
    if(textChunks.length === 0){
        return null
    }
    return textChunks;
}


export const formatAITags = (data) => {
    let [prompts, otherParas] = data.text.split("Steps: ");
    let promptSplit = prompts.split("Negative prompt: ");
    let negativePrompt = promptSplit.length > 1 ? promptSplit[1] : "无";
    return [
        {
            keyword: "提示词",
            text: promptSplit[0],
        },
        {
            keyword: "负面提示词",
            text: negativePrompt,
        },
        {
            keyword: "其他参数",
            text: "Steps: " + otherParas,
        },
    ];
}