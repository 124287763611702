function ExifInfo({ title, data }) {
    return (
        <div>
                <h2 className='text-xl'>图片信息</h2>
                <div className="my-2 text-xs p-1 px-2 rounded-md bg-white">
                {
                    data.map((item) => {
                        return <ExifItem key={item.keyword} {...item} />
                    })
                }
                </div>
        </div>
    )
}

export default ExifInfo


const ExifItem = ({ keyword, value }) => {
    return (
        <>
            <p className='text-xs'>
                {keyword}:{value.description}
            </p>
        </>
    )
}