import React from 'react'

function Footer() {
  return (
    <footer id="colophon" className="site-footer pt-10">
			<div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                <p className="text-center text-sm text-gray-500">
                    © 2021 <a href="http://www.moligirls.com" className="text-gray-600 hover:text-gray-500">MoliGirls</a>. All rights reserved.
                </p>
            </div>
	</footer>
  )
}

export default Footer