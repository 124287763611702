import CopyIcon from './assets/copy_text.svg'
import CopySuccessIcon from './assets/success.svg'
import { useState } from 'react'

const CopyBtn = ({ text }) => {
    const [copied, setCopied] = useState(false)

    const copy = () => {
        navigator.clipboard.writeText(text);
        console.log('copied')
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }
    return (
        <>
            {
                copied ? <span className='text-gray-400 text-xs flex'><img src={CopySuccessIcon} alt='' className='w-4 h-4 ml-2' /> 复制成功</span> : <span className='inline'>
                    <img src={CopyIcon} alt="" className='w-5 h-5 ml-2 cursor-pointer' onClick={copy} />
                </span>
            }

        </>
    )
}

export default CopyBtn