import { createContext, useState } from "react";
const InfoContext = createContext();
export default InfoContext;


export const InfoProvider = ({ children }) => {
    const [imageExif, setImageExif] = useState(null);
    const [imageRawTags,setImageRawTags] = useState(null);
    const [imageFormatTags,setImageFormatTags] = useState(null);
    const [imageSelected, setImageSelected] = useState(false);
    return (
        <InfoContext.Provider value={{imageExif,setImageExif,imageRawTags,setImageRawTags,imageFormatTags,setImageFormatTags,imageSelected,setImageSelected}}>
            {children}
        </InfoContext.Provider>
    )
}