import Footer from "./Footer";
import Head from "./Head";
import Image from "./Image";
import Info from "./Info";
function App() {
  return (
    <div className="App bg-pink-100 min-h-screen flex flex-col">
      <div className="main flex-grow">
        <Head />
        <div className="max-w-7xl mx-auto px-4 flex flex-col md:flex-row gap-8">
          <div className="md:w-1/2">
            <Image />
          </div>
          <div className="md:w-1/2">
            <Info />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
